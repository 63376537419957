

import { defineComponent, onMounted, watch, ref } from "vue";
import ApexChart from "apexcharts";
import { ContentLoader } from 'vue-content-loader';

export default defineComponent({
  name: "PercentualCategoriasCard",
  components: {
    ContentLoader
  },
  props: {
    title: String,
    percentual: Object,
    checklist: Number,
    orcamentos: Object,
    orcamentoReprovados: Object,
    orcamentosParcial: Object,
    totalOrcamentos: Object,
    animate: Boolean,
  },

  setup(props) {
    //declaração de variaveis//
    let chartInstance; 
    const image = "https://preview.keenthemes.com/metronic8/demo1/assets/media/svg/shapes/abstract-1.svg";


    function geraOptions(labels, colorsSemzero ) {
      const newOptions = {
        dataLabels: {
          enabled: true,
        },
        labels,
        chart: {
          width: 225,
          height: 225,
          type: 'donut',
          options: "chart",
          series: "series",
          offsetY: -30
        },
        grid: {
		    	show: true,
          clipMarkers: true,
          padding: {
          	left: 0,
              right: 0,
              top: 40,
              bottom: 0
          }
        },
        stroke: {
          show: true,
          curve: 'smooth',
          lineCap: 'butt',
          colors: undefined,
          width: 2,
          dashArray: 0,      
        },
        plotOptions: {
          pie: {
            customScale: 0.95,
            donut: {
              size: '60%'
            }
          }
        },
        colors: colorsSemzero,
        fill: {
           colors: colorsSemzero,
        },
        legend: {
          show:false,
        },
      }

      return newOptions;
    };


    onMounted(() => {
      let labels:any = [""];
      let colors:any = [""];

      const options = geraOptions(labels, colors);

      console.log("document.querySelector", document.querySelector("#graficoPacotes"));
      chartInstance = new ApexChart(document.querySelector("#graficoPacotes"), {
        ...options,
        series: [0, 0, 0],
      });
      console.log("chartInstance", chartInstance);
      chartInstance.render();
    })

    watch(() => props.percentual,  ( ) => {
      console.log("props.percentual", props.percentual)
     /*  console.log("props.percentual", props.percentual) */
      let array = props.percentual?.series;
      let arr =  Object.keys(array).map(function(key) {
        return array[key];
      });

      let labels:any = [];
      let series:any = [];
      let colors:any = [];
   
      if(arr.length > 0){
        arr.forEach((serie, index) => {
          if(serie > 0){   
            series.push(serie);
            labels.push(props.percentual?.labels[index]);
            colors.push(props.percentual?.colors[index]);
          }
        });

        if(series.length > 0){
          const options = geraOptions(labels, colors);
          chartInstance.updateSeries(series); 
          chartInstance.updateOptions(options);
        }
      }

      /* console.log("props.percentual?.series", labelsSemZero, seriesSemZero, colorsSemzero) */
    });
  
    return {
      image
    };
  },
});
