import ApiService from "./ApiService";
import { ProdutosMaisVendidos } from "@/models/OnePageReportModel";


/**
 * @description lista os faturamentos totais da oficina digital
 */
export const getIndicadorVendaMensal= async (codConcessionaria: number, filters: string) => {
   const response = await ApiService.get(`/faturamentostotaisonepagereport/${codConcessionaria}/${filters}`);
   return response.data;
};


export const getFaturamentosTotais = async (codConcessionaria: number, filters: string) => {
   const response = await ApiService.get(`/faturamentostotaisonepagereport/${codConcessionaria}/${filters}`);
   return response.data;
};

export const getFaturamentoUltimosMeses = async (codConcessionaria: number, filters: string) => {
   const response = await ApiService.get(`/faturamentostotaisonepagereport/${codConcessionaria}/${filters}`);
   return response.data;
};

export const getFaturamentoUltimos6Meses = async (codConcessionaria: number, filters: string) => {
   const response = await ApiService.get(`/graficofaturamentoagregadosxrevisoes/${codConcessionaria}/${filters}`);
   return response.data;
};

export const getProdutosMaisVendidos = async (codConcessionaria: number, filters: string) : Promise<Array<ProdutosMaisVendidos>> => {
   const { data } = await ApiService.get(`/produtosmaisvendidos/${codConcessionaria}/${filters}`);
   return data;
};

export const getGraficosParticipacaoPacotes = async (codConcessionaria: number, filters: string) => {
   const { data } = await ApiService.get(`/graficoparticipacaopacotesonepagereport/${codConcessionaria}/${filters}`);
   return data;
};

export const getChecklists = async (codConcessionaria: number, filters: string) => {
   const { data } = await ApiService.get(`/qtdchecklists/${codConcessionaria}/${filters}`);
   return data;
};

export const getOrcamentos = async (codConcessionaria: number, filters: string) => {
   const { data } = await ApiService.get(`/qtdorcamentos/${codConcessionaria}/${filters}`);
   return data;
};

export const getUsuariosApp = async (codConcessionaria: number, filters: string) => {
   const { data } = await ApiService.get(`/qtdusuariosapp/${codConcessionaria}/${filters}`);
   return data;
};

export const getAcessosApp = async (codConcessionaria: number, filters: string) => {
   const { data } = await ApiService.get(`/qtdacessosapp/${codConcessionaria}/${filters}`);
   return data;
};

export const getFaturamentoOrcamentos = async (codConcessionaria: number, filters: string) => {
   const { data } = await ApiService.get(`/faturamentoorcamentos/${codConcessionaria}/${filters}`);
   return data;
};

export const getIndicadoresConsultores = async (codConcessionaria: number, filters: string) => {
   const { data } = await ApiService.get(`/indicadoresconsultoresonepagereport/${codConcessionaria}/${filters}`);
   return data;
};

export const getVendasCategoriasConsultor = async (codConcessionaria: number, filters: string) => {
   const { data } = await ApiService.get(`/vendascategoriasconsultor/${codConcessionaria}/${filters}`);
   return data;
};

export const getVendasProdutosConsultor = async (codConcessionaria: number, filters: string) => {
   const { data } = await ApiService.get(`/vendasprodutosconsultor/${codConcessionaria}/${filters}`);
   return data;
};

export const getMetasConcessionarias = async (codConcessionaria: number) => {
   const { data } = await ApiService.get(`/metas/${codConcessionaria}`);
   return data;
};

export const getPesquisasConcessionaria = async (codConcessionaria: number) => {
   const { data } = await ApiService.get(`/getPesquisasConcessionaria/${codConcessionaria}`);
   return data;
};

export const getPesquisasRealizadas = async (codConcessionaria: number, codpesquisa: number, postData) => {
   const { data } = await ApiService.post(`/getPesquisasRealizadas/${codConcessionaria}/${codpesquisa}`, postData);
   return data;
};

export const sendEmailExport = async (postData) => {
   const { data } = await ApiService.post(`/enviaremail`, postData);
   return data;
};

export const getPesquisasAbaixoMedia = async (codConcessionaria: number, codpesquisa: number, postData) => {
   const { data } = await ApiService.post(`/getPesquisasAbaixoMedia/${codConcessionaria}/${codpesquisa}`, postData);
   return data;
};

export const getPesquisasAcimaMedia = async (codConcessionaria: number, codpesquisa: number, postData) => {
   const { data } = await ApiService.post(`/getPesquisasAcimaMedia/${codConcessionaria}/${codpesquisa}`, postData);
   return data;
};


export const getPDFOnePageReportCompleto = async (distribuidor:string, codConcessionaria: number, filters: string) => {
   const { data } = await ApiService.get(`/pdf/onepagereport/completo/${distribuidor}/${codConcessionaria}/${filters}`);
   return data;
};

export const getPDFOnePageReportSimplificado = async (distribuidor:string, codConcessionaria: number, filters: string) => {
   const { data } = await ApiService.get(`/pdf/onepagereport/simplificado/${distribuidor}/${codConcessionaria}/${filters}`);
   return data;
};

export const getPDFProdutosMaisVendidos = async (distribuidor:string, codConcessionaria: number, filters: string) => {
   const { data } = await ApiService.get(`/pdf/produtosMaisVendidos/${distribuidor}/${codConcessionaria}/${filters}`);
   return data;
};

export const getPDFProdutosServicosPdf = async (distribuidor:string, codConcessionaria: number, filters: string) => {
   const { data } = await ApiService.get(`/pdf/produtosServicosPdf/${distribuidor}/${codConcessionaria}/${filters}`);
   return data;
};

export const getInformacoesPorConsultorPdf = async (distribuidor:string, codConcessionaria: number, filters: string) => {
   const { data } = await ApiService.get(`/pdf/indicadoresconsultoresonepagereport/${distribuidor}/${codConcessionaria}/${filters}`);
   return data;
};
export const getFaturamento6MesesPdf = async (distribuidor:string, codConcessionaria: number, filters: string) => {
   const { data } = await ApiService.get(`/pdf/faturamento6meses/${distribuidor}/${codConcessionaria}/${filters}`);
   return data;
};

export default { getFaturamentoUltimosMeses, getFaturamentosTotais, getProdutosMaisVendidos, getFaturamentoUltimos6Meses,
    getIndicadorVendaMensal, getGraficosParticipacaoPacotes, getChecklists, getMetasConcessionarias, getVendasCategoriasConsultor,
     getVendasProdutosConsultor, getPesquisasRealizadas, getPesquisasAbaixoMedia, getPesquisasAcimaMedia, getPesquisasConcessionaria, 
     getPDFOnePageReportCompleto,getPDFOnePageReportSimplificado ,getPDFProdutosMaisVendidos,getPDFProdutosServicosPdf, getInformacoesPorConsultorPdf,
     getFaturamento6MesesPdf, sendEmailExport
    };
