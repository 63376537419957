
import { ContentLoader } from 'vue-content-loader';
import { defineComponent, onMounted, watch, ref } from "vue";
import ApexChart from "apexcharts";

export default defineComponent({
  name: "HistoricoOrcamentosRealizados",
  components: {
    ContentLoader
  },
  props: {
    historicoOrcamentos: Object,
    periodoTitle: String,
    animate: Boolean,
  },
  setup(props) {

    function geraOptions(categories, series) {
      const options = {
        chart: {
          type: 'bar',
          height: 250,
          toolbar: {
            show: false,
          }, 
        },
        grid: {
          borderColor: '#f1f1f1',
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: percentWidthfuncao(series),
            dataLabels: {
									position: 'top',
								},
            endingShape: 'rounded',
            borderRadius: 10,
            style: {
               colors: 'var(--cor_indicadores_2)',
            },
          },
        },
         colors:['var(--cor_indicadores_2)', 'var(--cor_indicadores_1)'],
        dataLabels: {
          enabled: false,
          offsetY: -40,
          position: 'top',           
             style: {
                    fontSize: '15px',
                    colors: ['var(--cor_texto_1)'],
               },
        },
        stroke: {
          show: true,
          width: 6,
          colors: ['transparent']
        },
        xaxis: {
          categories,
        },
        yaxis: {
          title: {
            text: 'Quantidade'
          },
          
        },
        fill: {
          opacity: 1
        },
      }

      return options;
    }

    function geraSeries(orcamentosTotais, orcamentosAprovados) {
      if(orcamentosTotais && orcamentosAprovados){
          const series = [
          {
            name: "Orçamentos realizados",
            data: orcamentosTotais.map(dado => dado ? Number(dado) : 0),
          },
          {
            name: "Orçamentos aprovados",
            data: orcamentosAprovados.map(dado => dado ? Number(dado) : 0),
          },
        ];
        return series; 
      }
        
      return []
    }    

    function percentWidthfuncao(orcamentosAprovados)  {
      if(orcamentosAprovados){
        const x = 60;
        const n =  orcamentosAprovados.length;
        const w = 1000;
        const percentWidth = Math.round((x / (w / n)) * 100)
        return `${percentWidth}%`
      }
      
      return '0%'
    } 

    function resizeWithTime(time = 100){
      setTimeout(() => window.dispatchEvent(new Event('resize')), time)
    }

    let chartInstance;
    onMounted(() => {
      const options = geraOptions([], []);
      const series = geraSeries([], []);

      chartInstance = new ApexChart(
        document.querySelector("#graficoComparacaoHistorico"),
        {
          ...options,
          series,
        }
      );
      chartInstance.render();
    });
    
    const seriesRef = ref();

    watch(() => props.historicoOrcamentos,() => {
        if (props.historicoOrcamentos) {

          seriesRef.value = props.historicoOrcamentos.series;
          faturamentoDosMeses(props.historicoOrcamentos.series,  props.historicoOrcamentos.seriesAprovados, props.historicoOrcamentos.categorias);      
        }
      }
    );

    function faturamentoDosMeses(refSeries, refSeriesAprovados, refOtions) {
      if(refSeries && refSeriesAprovados && refOtions){
        const series = geraSeries(refSeries, refSeriesAprovados);
        const options = geraOptions(refOtions, refSeriesAprovados);        

        chartInstance.updateOptions(options); 
        chartInstance.updateSeries(series);
        resizeWithTime();
      }    
    }

    return {
      seriesRef,
 
    };
  },
});
